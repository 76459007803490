export const MapThemeToClassName = {
	light: "lightRepGen",
	dark: "darkRepGen",
};

export const setTheme = (themeName) => {
	localStorage.setItem("RepGenTheme", themeName);
	document.documentElement.className = MapThemeToClassName?.[themeName] ?? themeName;
};

export const themeCheck = (selectedTheme) => {
	if (selectedTheme === "lightRepGen" || "light") {
		setTheme("light");
	}
	if (selectedTheme === "darkRepGen" || "dark") {
		setTheme("dark");
	}
};

export const setUpTheme = () => {
	let themeType = localStorage.getItem("RepGenTheme");
	const selectedTheme = themeType ?? "light";
	themeCheck(selectedTheme);
};
