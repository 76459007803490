import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Home from "./routes/Home/Home";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./MSALConfig";
import { MsalProvider } from "@azure/msal-react";
import Tenants from "./routes/Tenants/Tenants";
import { EnvironmentContextProvider } from "./context/EnvironmentContextProvider";
import Datasources from "./routes/Datasources/Datasources";
import { SystemReports } from "./routes/SystemReports/SystemReports";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import EmailSettings from "./routes/Email/EmailSettings";
import TenantSettings from "./routes/TenantSettings/TenantSettings";
import ConversionSettings from "./routes/ConversionSettings/ConversionSettings";
import DeleteFunction from "./routes/DeleteFunction/DeleteFunction";
import { Troubleshooting } from "./routes/Troubleshooting/Troubleshooting";
import { UpdateBase } from "./routes/UpdateBase/UpdateBase";
import { ClarityBanner } from "./components/ClarityBanner/ClarityBanner";
import { Audit } from "./routes/Audit/Audit";
import { Conversion } from "./routes/Conversion/Conversion";
import { ConversionDetails } from "./routes/Conversion/ConversionDetails";
import { MoveReports } from "./routes/MoveReports/MoveReports";

export const msalInstance = new PublicClientApplication(msalConfig);

const router = createBrowserRouter([
	{
		path: "*",
		element: <Navigate to={"/"} />,
		errorElement: <ErrorPage />,
	},
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
			},
			{
				path: "tenants",
				element: <Tenants />,
			},
			{
				path: ":tenantName/datasources",
				element: <Datasources />,
			},
			{
				path: ":tenantName/settings",
				element: <TenantSettings />,
				children: [
					{
						path: "email",
						element: <EmailSettings />,
					},
					{
						path: "conversion",
						element: <ConversionSettings />,
					},
					{
						path: "delete",
						element: <DeleteFunction />,
					},
				],
			},
			{
				path: "systemReports",
				element: <SystemReports />,
			},
			{
				path: "troubleshooting",
				element: <Troubleshooting />,
			},
			{
				path: "updatebase",
				element: <UpdateBase />,
			},
			{
				path: "audit",
				element: <Audit />,
			},
			{
				path: "conversions",
				element: <Conversion />,
			},
			{
				path: "conversions/:conversionId",
				element: <ConversionDetails />,
			},
			{
				path: "movereports",
				element: <MoveReports />,
			},
		],
	},
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<QueryClientProvider client={queryClient}>
				<EnvironmentContextProvider>
					<RouterProvider router={router} />
					<ClarityBanner />
				</EnvironmentContextProvider>
			</QueryClientProvider>
		</MsalProvider>
	</React.StrictMode>,
);
