import { useContext, useState } from "react";
import "./MoveReports.scss";
import { useTenants } from "../../hooks/useTenants";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { Button, Selectbox, Tabs, TextArea } from "@streetsheaver/sh-ui";
import { useMutation } from "@tanstack/react-query";
import { callAPI } from "../../helpers/callAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";

const MoveTitleReports = ({ selectedOptions, setSelectedOptions }) => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const { tenants } = useTenants();
	const { datasources: fromDatasources, datasourcesLoading: fromDatasourcesLoading } = useTenantDatasources(
		selectedEnv,
		selectedOptions.fromTenantId,
	);
	const { datasources: toDatasources, datasourcesLoading: toDatasourcesLoading } = useTenantDatasources(
		selectedEnv,
		selectedOptions.toTenantId,
	);

	const moveReportByTitlesMutation = useMutation({
		mutationFn: async () => {
			return await callAPI({
				selectedEnv: selectedEnv,
				url: `tenants/${selectedOptions.toTenantId}/data-sources/${selectedOptions.toDatasourceId}/add-report-batch`,
				body: selectedOptions.titleNames,
				method: "post",
				params: `&fromTenantId=${selectedOptions.fromTenantId}&fromdataSourceid=${selectedOptions.fromDatasourceId}`,
			});
		},
	});

	return (
		<>
			<p>From</p>
			<Selectbox
				items={tenants}
				title={"Tenants"}
				isRequired
				displayExpr={"name"}
				onChange={(e) => setSelectedOptions((prev) => ({ ...prev, fromTenantId: e.id, fromDatasourceId: null }))}
				startingSelection={tenants.find((t) => t.id === selectedOptions.fromTenantId)}
			/>
			{fromDatasourcesLoading ? (
				<div style={{ position: "relative" }}>
					<LoadingSpinner />
				</div>
			) : (
				fromDatasources !== null && (
					<Selectbox
						key={`from-${selectedOptions.fromTenantId}`}
						items={fromDatasources}
						title={"Datasource"}
						isRequired
						displayExpr={"name"}
						onChange={(e) => setSelectedOptions((prev) => ({ ...prev, fromDatasourceId: e.id }))}
						startingSelection={fromDatasources?.find((d) => d.id === selectedOptions.fromDatasourceId)}
					/>
				)
			)}
			<p>To</p>
			<Selectbox
				items={tenants}
				title={"Tenants"}
				isRequired
				displayExpr={"name"}
				onChange={(e) => setSelectedOptions((prev) => ({ ...prev, toTenantId: e.id, toDatasourceId: null }))}
				startingSelection={tenants.find((t) => t.id === selectedOptions.toTenantId)}
			/>
			{toDatasourcesLoading ? (
				<div style={{ position: "relative" }}>
					<LoadingSpinner />
					<p>Test</p>
				</div>
			) : (
				toDatasources !== null && (
					<Selectbox
						key={`to-${selectedOptions.toTenantId}`}
						items={toDatasources}
						title={"Datasource"}
						isRequired
						displayExpr={"name"}
						onChange={(e) => setSelectedOptions((prev) => ({ ...prev, toDatasourceId: e.id }))}
						startingSelection={toDatasources?.find((d) => d.id === selectedOptions.toDatasourceId)}
					/>
				)
			)}
			<TextArea
				title={"Report Titles (Should be comma separated)"}
				isRequired
				onChange={(e) => setSelectedOptions((prev) => ({ ...prev, titleNames: e.target.value }))}
			/>
			<div style={{ paddingTop: "24px" }}>
				<Button
					type={"CTA"}
					text={"Move to selected datasource"}
					disable={Object.values(selectedOptions).some((v) => v === null)}
					unpadded
					takeContainerWidth
					onClick={() => moveReportByTitlesMutation.mutate()}
					icon={moveReportByTitlesMutation.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
				/>
			</div>
		</>
	);
};

export const MoveReports = () => {
	const [moveType, setMoveType] = useState("JSON");
	const { selectedEnv } = useContext(EnvironmentContext);
	const [selectedOptions, setSelectedOptions] = useState({
		tenantId: selectedEnv === "Dev" ? "73b2231a-113f-4276-954d-6aa6139488ca" : null,
		datasourceId: selectedEnv === "Dev" ? "25e3a368-c7de-445c-88ac-b5bc7c0b03f6" : null,
		json: null,
	});
	const { tenants, tenantsLoading } = useTenants();
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, selectedOptions.tenantId);

	const moveReportMutation = useMutation({
		mutationFn: async () => {
			return await callAPI({
				selectedEnv: selectedEnv,
				url: `tenants/${selectedOptions.tenantId}/data-sources/${selectedOptions.datasourceId}/add-report`,
				body: selectedOptions.json,
				method: "post",
			});
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;
	if (tenantsLoading) return <LoadingSpinner />;

	return (
		<div className="moveReports">
			<h1>Move Reports</h1>
			<Tabs
				tabs={["JSON", "Titles"]}
				defaultTab={moveType}
				onChange={(e) => {
					setSelectedOptions(
						e === "JSON"
							? {
									tenantId: selectedEnv === "Dev" ? "73b2231a-113f-4276-954d-6aa6139488ca" : null,
									datasourceId: selectedEnv === "Dev" ? "25e3a368-c7de-445c-88ac-b5bc7c0b03f6" : null,
									json: null,
							  }
							: {
									fromTenantId: selectedEnv === "Dev" ? "73b2231a-113f-4276-954d-6aa6139488ca" : null,
									fromDatasourceId: selectedEnv === "Dev" ? "25e3a368-c7de-445c-88ac-b5bc7c0b03f6" : null,
									toDatasourceId: null,
									toTenantId: null,
									titleNames: null,
							  },
					);
					setMoveType(e);
				}}
				key={moveType}
			/>
			{moveType === "JSON" ? (
				<>
					<Selectbox
						items={tenants}
						title={"Tenants"}
						isRequired
						displayExpr={"name"}
						onChange={(e) => setSelectedOptions((prev) => ({ ...prev, tenantId: e.id, datasourceId: null }))}
						startingSelection={tenants.find((t) => t.id === selectedOptions.tenantId)}
					/>
					{datasourcesLoading ? (
						<div style={{ position: "relative" }}>
							<LoadingSpinner />
						</div>
					) : (
						datasources !== null && (
							<Selectbox
								key={`JSON-${selectedOptions.tenantId}`}
								items={datasources}
								title={"Datasource"}
								isRequired
								displayExpr={"name"}
								onChange={(e) => setSelectedOptions((prev) => ({ ...prev, datasourceId: e.id }))}
								startingSelection={datasources.find((d) => d.id === selectedOptions.datasourceId)}
							/>
						)
					)}
					<TextArea
						title={"Report JSON"}
						isRequired
						onChange={(e) => setSelectedOptions((prev) => ({ ...prev, json: e.target.value }))}
					/>
					<div style={{ paddingTop: "24px" }}>
						<Button
							type={"CTA"}
							text={"Move to selected datasource"}
							disable={Object.values(selectedOptions).some((v) => v === null)}
							unpadded
							takeContainerWidth
							onClick={() => moveReportMutation.mutate()}
							icon={moveReportMutation.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
						/>
					</div>
				</>
			) : (
				<MoveTitleReports selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} />
			)}
		</div>
	);
};
