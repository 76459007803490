import {
	faAt,
	faDatabase,
	faEnvelope,
	faFileSlash,
	faPenToSquare,
	faTrashCanSlash,
	faUserMinus,
	faUserPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";

export const getActionFromType = (a) => {
	switch (a.actionType) {
		case "CreateDataSource":
			return `Created Data Source ${a.actionDetails.DataSourceId}`;
		case "EditDataSource":
			return `Edited Data Source ${a.actionDetails.DataSourceId}`;
		case "DeleteDataSource":
			return `Deleted Data Source ${a.actionDetails.DataSourceId}`;
		case "CreateTenant":
			return "Created A Tenant";
		case "DeleteTenant":
			return "Deleted Tenant";
		case "DeleteSystemReports":
			return "Deleted System Reports";
		case "UpdateSupportFiles":
			return "Updated Support Files";
		case "AddOrEditEmailSettings":
			return "Added/Edited Email Settings";
		case "RemoveEmailSettings":
			return "Removed Email Settings";
		case "DeleteData":
			return "Deleted Data";
		default:
			return "performed action";
	}
};

const DatasourceChangeDictionary = {
	replace: "Changed",
	"/name": "name",
	"/status": "status",
	"/connectionString": "connection string",
	1: "Online",
	2: "Offline",
	3: "Under Maintenance",
	4: "Hidden",
};

const AuditDocumentType = {
	0: "Schedules",
	1: "Blobs",
	2: "UserFavourites",
	3: "UserRecents",
	4: "Reports",
	5: "Runs",
	6: "Tags",
	7: "None",
	8: "All",
};

export const getIcon = (actionType) => {
	switch (actionType) {
		case "CreateDataSource":
			return <FontAwesomeIcon icon={faDatabase} />;
		case "EditDataSource":
			return <FontAwesomeIcon icon={faDatabase} />;
		case "DeleteDataSource":
			return <FontAwesomeIcon icon={faDatabase} />;
		case "CreateTenant":
			return <FontAwesomeIcon icon={faUserPlus} />;
		case "DeleteTenant":
			return <FontAwesomeIcon icon={faUserMinus} />;
		case "DeleteSystemReports":
			return <FontAwesomeIcon icon={faFileSlash} />;
		case "UpdateSupportFiles":
			return <FontAwesomeIcon icon={faPenToSquare} />;
		case "AddOrEditEmailSettings":
			return <FontAwesomeIcon icon={faEnvelope} />;
		case "RemoveEmailSettings":
			return <FontAwesomeIcon icon={faAt} />;
		case "DeleteData":
			return <FontAwesomeIcon icon={faTrashCanSlash} />;
		default:
			return null;
	}
};

export const getTitle = (audit, tenants) => {
	switch (audit.actionType) {
		case "DeleteTenant":
		case "CreateTenant":
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						fontWeight: 700,
						fontSize: "16px",
					}}
				>
					<p style={{ fontWeight: 700, fontSize: "16px" }}>{`${
						audit?.username?.length !== 0 ? audit.username : "Anonymous"
					} ${getActionFromType(audit)} ${
						audit.actionDetails?.TenantId
							? `${tenants.find((t) => t.id === audit.actionDetails?.TenantId)?.name ?? audit.actionDetails?.TenantId}`
							: ""
					}`}</p>
					<p style={{ fontWeight: 700, fontSize: "16px" }}>{`${dayjs(audit.timeCompleted).format(
						"MMMM D, YYYY [at] HH:mm",
					)}`}</p>
				</div>
			);
		default:
			return (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						fontWeight: 700,
						fontSize: "16px",
					}}
				>
					<p style={{ fontWeight: 700, fontSize: "16px" }}>{`${
						audit?.username?.length !== 0 ? audit.username : "Anonymous"
					} ${getActionFromType(audit)} ${
						audit.actionDetails?.TenantId
							? `for Tenant ${
									tenants.find((t) => t.id === audit.actionDetails?.TenantId)?.name ?? audit.actionDetails?.TenantId
							  }`
							: ""
					}`}</p>
					<p style={{ fontWeight: 700, fontSize: "16px" }}>
						{dayjs(audit.timeCompleted).format("MMMM D, YYYY [at] HH:mm")}
					</p>
				</div>
			);
	}
};

export const getSubtext1 = (audit, tenants) => {
	const tenantNameOrId =
		tenants.find((t) => t.id === audit.actionDetails?.TenantId)?.name ?? audit.actionDetails?.TenantId;
	switch (audit.actionType) {
		case "CreateDataSource":
			return <p>{`Added datasource with ID ${audit.actionDetails?.DataSourceId} to ${tenantNameOrId}`}</p>;
		case "EditDataSource":
			return audit.actionDetails.Changes.map((change) => {
				if (change.path === "/connectionString")
					return (
						<p>{`${DatasourceChangeDictionary[change.op]} the datasources ${
							DatasourceChangeDictionary[change.path]
						}`}</p>
					);
				return (
					<p>{`${DatasourceChangeDictionary[change.op]} the datasources ${DatasourceChangeDictionary[change.path]} to ${
						DatasourceChangeDictionary[change.value] ?? change.value
					}`}</p>
				);
			});
		case "DeleteDataSource":
			return <p>{`Deleted datasource with ID ${audit.actionDetails?.DataSourceId} from ${tenantNameOrId}`}</p>;
		case "CreateTenant":
			return <p>{`Created tenant ${tenantNameOrId}`}</p>;
		case "DeleteTenant":
			return;

		case "DeleteSystemReports":
			return "Request Body for deleting system reports";
		case "UpdateSupportFiles":
			return (
				<p>{`Updated ${audit.actionDetails.UpdatedDocumentIds.length} documents across ${audit.actionDetails.PullRequestIds.length} pull requests`}</p>
			);
		case "AddOrEditEmailSettings":
			return generateEmailSettingChanges(
				audit.actionDetails.NewEmailSettings,
				audit.actionDetails.OldEmailSettings ?? {},
			);
		case "RemoveEmailSettings":
			return;
		case "DeleteData":
			return `Deleted ${audit.actionDetails.DocumentIds.length} ${
				AuditDocumentType[audit.actionDetails.DocumentType]
			} ${
				audit.actionDetails.DataSourceId !== "null" ? `from datasource ${audit.actionDetails.DataSourceId}` : ""
			} for Tenant ${tenantNameOrId}`;
		default:
			return;
	}
};

const generateEmailSettingChanges = (newSettings, oldSettings) => {
	return Object.keys(newSettings).reduce((differences, key) => {
		if (newSettings[key] !== oldSettings[key]) {
			differences.push(<p>{`Changed ${key} to ${newSettings[key]}`}</p>);
		}
		return differences;
	}, []);
};
