import React, { useContext, useState } from "react";
import "./DeleteFunction.scss";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import { useLocation, useParams } from "react-router";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { Button, Popup, Selectbox, Switch, Textbox, Toast } from "@streetsheaver/sh-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { useMutation } from "@tanstack/react-query";
import { callAPI } from "../../helpers/callAPI";

const items = ["None", "ConvertedOnly", "All"];

export default function DeleteFunction() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const tenantId = useLocation()?.state?.id;
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, tenantId);
	const datasourcesOptions = [{ name: "All" }, ...(datasources ? datasources : [])];
	const [dbToDeleteFrom, setDbToDeleteFrom] = useState(false);
	const [deleteOptions, setDeleteOptions] = useState({
		blobs: "None",
		favouriteReports: false,
		recentReports: false,
		reports: "None",
		runs: "None",
		tags: false,
	});
	const [deletePopup, setDeletePopup] = useState(false);
	const [toast, setToast] = useState({ visible: false, type: "", text: "" });

	if (datasourcesLoading) return <FontAwesomeIcon icon={faFile} spin />;

	return (
		<div className="Delete">
			<Selectbox
				title={"Blobs"}
				items={items}
				startingSelection={items[0]}
				onChange={(e) => setDeleteOptions({ ...deleteOptions, blobs: e })}
			/>
			<div className="Delete__switch">
				<p>Delete Users Favourite Reports</p>
				<Switch
					isOn={deleteOptions?.favouriteReports}
					handleToggle={() => setDeleteOptions({ ...deleteOptions, favouriteReports: !deleteOptions.favouriteReports })}
				/>
			</div>
			<div className="Delete__switch">
				<p>Delete Users Recent Reports</p>
				<Switch
					isOn={deleteOptions?.recentReports}
					handleToggle={() => setDeleteOptions({ ...deleteOptions, recentReports: !deleteOptions.recentReports })}
				/>
			</div>
			<Selectbox
				title={"Reports"}
				items={items}
				startingSelection={items[0]}
				onChange={(e) => setDeleteOptions({ ...deleteOptions, reports: e })}
			/>
			<Selectbox
				title={"Runs"}
				items={items}
				startingSelection={items[0]}
				onChange={(e) => setDeleteOptions({ ...deleteOptions, runs: e })}
			/>
			<div className="Delete__switch">
				<p>Delete Tags</p>
				<Switch
					isOn={deleteOptions?.tags}
					handleToggle={() => setDeleteOptions({ ...deleteOptions, tags: !deleteOptions.tags })}
				/>
			</div>
			<Selectbox
				title={"Datasources"}
				items={datasourcesOptions}
				displayExpr={"name"}
				startingSelection={datasourcesOptions[0]}
				onChange={(e) => {
					if (e.name === "All") return setDbToDeleteFrom(null);
					return setDbToDeleteFrom(e.id);
				}}
			/>
			<Button text={"Delete Selected Settings"} unpadded type={"CTA"} onClick={() => setDeletePopup(true)} />
			<Popup
				renderContent={
					<DeletePopupContent
						dbToDeleteFrom={dbToDeleteFrom}
						setDeletePopup={setDeletePopup}
						setToast={setToast}
						deleteOptions={deleteOptions}
					/>
				}
				noHeader
				noPadding
				visible={deletePopup}
				onOutsideClick={() => setDeletePopup(false)}
				onCloseButtonClick={() => setDeletePopup(false)}
			/>
			<Toast
				visible={toast?.visible}
				renderContent={toast?.text}
				timer={3000}
				timeoutFunction={() => setToast((prev) => ({ ...prev, visible: false }))}
				type={toast.type}
			/>
		</div>
	);
}

const DeletePopupContent = ({ dbToDeleteFrom, setDeletePopup, setToast, deleteOptions }) => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [tenantText, setTenantText] = useState("");
	const tenantId = useLocation()?.state?.id;
	const { tenantName } = useParams();
	const deleteDataMutation = useMutation({
		mutationFn: async (deleteOptions) => {
			return await callAPI({
				url: `tenants/${tenantId}/data`,
				params: `&dataSourceId=${dbToDeleteFrom}`,
				body: deleteOptions,
				method: "delete",
				selectedEnv: selectedEnv,
			});
		},
		onSuccess: (res) => {
			setDeletePopup(false);
			setToast({ visible: true, text: "Succesfully deleted tenant data", type: "success" });
		},
		onError: () => {
			setDeletePopup(false);
			setToast({ visible: true, text: "Failed to delete tenant data", type: "critical" });
		},
	});
	return (
		<div className="DeletePopup">
			<p>Are you sure you want to delete the selected data for this tenant?</p>
			{selectedEnv === "Production" && <p style={{ color: "var(--system-critical)" }}>THIS IS DATA FOR PRODUCTION</p>}
			<p>This is a destructive action and cannot be undone</p>
			<p>Please type the name of the tenant you are deleting data for below and press confirm</p>
			<Textbox value={tenantText} onChange={(e) => setTenantText(e.target.value)} />
			<div className="DeletePopup__buttons">
				<Button
					icon={deleteDataMutation.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
					type={"CTA"}
					text={"Confirm"}
					onClick={() =>
						tenantText === tenantName ? deleteDataMutation.mutate(deleteOptions) : console.log("Invalid")
					}
				/>
				<Button text={"Cancel"} onClick={() => setDeletePopup(false)} />
			</div>
		</div>
	);
};
