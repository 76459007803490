import React, { useContext, useState } from "react";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useLocation } from "react-router";
import { Button, ListCard, Popup, Selectbox, Textbox } from "@streetsheaver/sh-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import "./ConversionSettings.scss";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { openOrDownloadFile } from "../../helpers/DownloadFile";
import { callAPI } from "../../helpers/callAPI";

const getInvalidConvSettings = (convSettings) => {
	const invalidArray = [];
	if (convSettings?.SHReportingDbConnectionString?.length === 0) invalidArray.push("connectionString");
	if (convSettings?.DataSourceIds?.length === 0) invalidArray.push("datasourceIds");
	return invalidArray;
};

export default function ConversionSettings() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const tenantId = useLocation()?.state?.id;
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, tenantId);
	const [conversionForm, setConversionForm] = useState({
		TenantId: tenantId,
		SHReportingDbConnectionString: "",
		DataSourceIds: [],
	});
	const [formErrors, setFormErrors] = useState([]);
	const [showAddPopup, setShowAddPopup] = useState(false);
	const queryClient = useQueryClient();

	const { data: conversionSettings, isLoading: isLoadingConversionSettings } = useQuery({
		queryKey: ["ConversionSettings", selectedEnv, tenantId],
		queryFn: async () => {
			const res = await callAPI({ url: `tenants/${tenantId}/conversion-settings`, selectedEnv: selectedEnv });
			return res.data;
		},
		refetchOnWindowFocus: false,
		retry: (count, error) => {
			if (error?.response?.status === 404) return false;
			return true;
		},
	});

	const addConversionSettings = useMutation({
		mutationFn: async () => {
			const invalidEntries = getInvalidConvSettings(conversionForm);
			setFormErrors(invalidEntries);
			if (invalidEntries?.length > 0) return;
			return await callAPI({
				url: `tenants/${tenantId}/conversion-settings`,
				method: "post",
				body: conversionForm,
				selectedEnv: selectedEnv,
			});
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries(["ConversionSettings", selectedEnv, tenantId]);
			if (res) {
				setConversionForm({ TenantId: tenantId, SHReportingDbConnectionString: "", DataSourceIds: [] });
				setShowAddPopup(false);
			}
		},
	});

	const generateSettingsFile = useMutation({
		mutationFn: async (settings) => {
			const response = await callAPI({
				url: `tenants/${tenantId}/conversion-settings/generate`,
				selectedEnv: selectedEnv,
				body: settings,
				method: "post",
			});
			const blob = new Blob([response.data], { type: "application/json" });
			openOrDownloadFile(blob, "conversion.settings.json");
			return response;
		},
	});

	if (datasourcesLoading || isLoadingConversionSettings) return <FontAwesomeIcon icon={faFile} spin />;

	const AddConversionSettingsContent = (
		<div className="AddConversionSettings">
			<h1>Add New Conversion Settings</h1>
			<Textbox
				title={"Connection String (SH Reporting Database you are converting from)"}
				value={conversionForm?.SHReportingDbConnectionString}
				onChange={(e) => setConversionForm((prev) => ({ ...prev, SHReportingDbConnectionString: e.target.value }))}
				isError={formErrors.includes("connectionString")}
				errorMessage={"Connection string cannot be empty"}
			/>
			<Selectbox
				title={"Selected Datasources (Datasources to convert into)"}
				items={datasources}
				displayExpr={"name"}
				onChange={(e) => setConversionForm((prev) => ({ ...prev, DataSourceIds: [e?.id] }))}
				isError={formErrors.includes("datasourceIds")}
				errorMessage={"Datasource to convert to must be selected"}
			/>
			<Button text={"Generate Conversion Settings"} unpadded onClick={() => addConversionSettings.mutate()} />
		</div>
	);

	return (
		<div className="ConversionSettings">
			<div className="ConversionSettings__cards">
				{conversionSettings?.map((c, i) => (
					<ListCard
						key={i}
						title={`Version ${c?.version}`}
						subtext1={`Connection String: ${c?.connectionString}`}
						subtext2={
							c.dataSourceMap[-1] &&
							`Datasource Converting To: ${datasources.find((d) => d.id === c.dataSourceMap[-1]).name}`
						}
						button1={{ type: "CTA", label: "Download Settings", onClick: () => generateSettingsFile.mutate(c) }}
					/>
				))}
			</div>
			<Button unpadded type={"CTA"} text={"Add new settings"} onClick={() => setShowAddPopup(true)} />
			<Popup
				renderContent={AddConversionSettingsContent}
				noHeader
				noPadding
				visible={showAddPopup}
				onOutsideClick={() => setShowAddPopup(false)}
			/>
		</div>
	);
}
