import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { callAPI } from "../../helpers/callAPI";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { Button, Checkbox, Datebox, GhostCard, ListCard, Selectbox, Textbox } from "@streetsheaver/sh-ui";
import { getIcon, getSubtext1, getTitle } from "./AuditHelpers";
import { useTenants } from "../../hooks/useTenants";
import "./Audit.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";

export const Audit = () => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const { tenants, tenantsLoading } = useTenants();
	const navigate = useNavigate();
	const searchParamsObj = new URLSearchParams(window.location.search);
	const searchParamsAsString = useLocation().search;
	const filters = searchParamsAsString.toString().substring(1);
	const [isFiltersVisible, setIsFiltersVisible] = useState(true);

	const {
		data: auditData,
		isLoading: auditsLoading,
		fetchNextPage: fetchMoreAudits,
	} = useInfiniteQuery({
		queryKey: ["Audits", selectedEnv, filters],
		queryFn: async ({ pageParam = 0 }) => {
			const take = 10;
			const skip = pageParam * take;
			const auditRes = await callAPI({
				selectedEnv: selectedEnv,
				url: `audits`,
				params: `&skip=${skip}${filters.length > 0 ? `&${filters}` : ""}`,
			});
			const auditData = auditRes.data.Items.map((event) => {
				const changes = event?.actionDetails?.Changes?.map((change) => JSON.parse(change));
				if (changes)
					return {
						...event,
						actionDetails: {
							...event.actionDetails,
							Changes: changes,
						},
					};

				return event;
			});
			return {
				...auditRes.data,
				Items: auditData,
			};
		},
		getNextPageParam: (lastPage, allPages) => {
			if (!lastPage?.HasMore || lastPage?.Items.length === 0) {
				return undefined;
			}
			return allPages.length;
		},
	});

	const updateFilters = (key, value) => {
		searchParamsObj.set(key, value);
		navigate({ search: createSearchParams(searchParamsObj).toString() }, { replace: true });
	};

	const deleteFilters = (key, value) => {
		searchParamsObj.delete(key, value);
		navigate({ search: createSearchParams(searchParamsObj).toString() }, { replace: true });
	};

	const handlePagination = (res) => res?.pages?.flatMap((page) => page?.Items) || [];

	if (selectedEnv === null) return <NoEnvSelected />;

	if (tenantsLoading) return <LoadingSpinner />;

	return (
		<div
			className="audits"
			onScroll={(e) => {
				if (e.target.clientHeight * 1.005 >= e.target.scrollHeight - e.target.scrollTop) {
					fetchMoreAudits();
				}
			}}
		>
			<h1 style={{ textAlign: "center" }}>Audits</h1>
			<div onClick={() => setIsFiltersVisible(!isFiltersVisible)} className="audits__filterButton">
				<FontAwesomeIcon icon={isFiltersVisible ? faChevronDown : faChevronRight} />
				<h2>Filters</h2>
			</div>
			{isFiltersVisible && (
				<div className="audits__filters">
					<Selectbox
						items={tenants}
						displayExpr={"name"}
						title={"Tenant"}
						onChange={(e) => {
							updateFilters("tenantId", e.id);
						}}
						key={`${searchParamsObj.get("tenantId")}-tenants`}
						startingSelection={tenants.find((t) => t.id === searchParamsObj.get("tenantId"))}
					/>
					<div>
						<p>Action Types</p>
						<div style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
							{[
								"CreateDataSource",
								"EditDataSource",
								"DeleteDataSource",
								"CreateTenant",
								"DeleteTenant",
								"DeleteSystemReports",
								"UpdateSupportFiles",
								"AddOrEditEmailSettings",
								"RemoveEmailSettings",
								"DeleteData",
							].map((action) => {
								const currActionTypes = searchParamsObj.get("actionTypes")?.split(",") ?? [];
								const isChecked = currActionTypes.includes(action) ?? false;
								return (
									<Checkbox
										label={action}
										checked={isChecked}
										onChange={(e) => {
											if (e.target.checked) {
												currActionTypes.push(action);
												updateFilters("actionTypes", currActionTypes.join(","));
											} else {
												const updatedActionTypes = currActionTypes
													.map((a) => {
														if (a === action) return null;
														return a;
													})
													.filter(Boolean);
												updatedActionTypes.length === 0
													? deleteFilters("actionTypes")
													: updateFilters("actionTypes", updatedActionTypes.join(","));
											}
										}}
									/>
								);
							})}
						</div>
					</div>
					<Datebox
						title={"Start Date"}
						onSelected={(e) => {
							const date = dayjs(e).startOf("day");
							updateFilters("startDate", date.toString());
						}}
						key={`${searchParamsObj.get("startDate")}-start`}
						defaultDate={searchParamsObj.get("startDate") ? new Date(searchParamsObj.get("startDate")) : null}
						showInitialDate={dayjs(searchParamsObj.get("startDate")).isValid()}
					/>
					<Datebox
						title={"End Date"}
						onSelected={(e) => {
							const date = dayjs(e).endOf("day");
							updateFilters("endDate", date.toString());
						}}
						key={`${searchParamsObj.get("endDate")}-end`}
						defaultDate={searchParamsObj.get("endDate") ? new Date(searchParamsObj.get("endDate")) : null}
						showInitialDate={dayjs(searchParamsObj.get("endDate")).isValid()}
					/>
					<Textbox
						title={"User Names (Comma Separated)"}
						onBlur={(e) => {
							updateFilters("usernames", e.target.value);
						}}
						key={`${searchParamsObj.get("usernames")}-usernames`}
						defaultValue={searchParamsObj.get("usernames") ?? ""}
					/>
					<Textbox
						title={"User IDs (Comma Separated)"}
						onBlur={(e) => {
							updateFilters("userIds", e.target.value);
						}}
						key={`${searchParamsObj.get("userIds")}-userIds`}
						defaultValue={searchParamsObj.get("userIds") ?? ""}
					/>
					<div style={{ width: "100%" }}>
						<Button
							text={"Reset Filters"}
							type={"cta"}
							unpadded
							takeContainerWidth
							onClick={() => navigate({ search: createSearchParams({}).toString() }, { replace: true })}
						/>
					</div>
				</div>
			)}
			<div className="audits__list">
				{auditsLoading ? (
					<GhostCard />
				) : handlePagination(auditData).length === 0 ? (
					<p>No audits for selected filters</p>
				) : (
					handlePagination(auditData).map((a) => (
						<ListCard title={getTitle(a, tenants)} icon={getIcon(a.actionType)} subtext1={getSubtext1(a, tenants)} />
					))
				)}
			</div>
		</div>
	);
};
