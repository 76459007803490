import React, { useContext, useState } from "react";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useQuery } from "@tanstack/react-query";
import { Button, Tabs, Textbox } from "@streetsheaver/sh-ui";
import { faSpinner } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Troubleshooting.scss";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { callAPI } from "../../helpers/callAPI";
import { CopyText } from "../../components/CopyText/CopyText";

const tabItems = ["Request Details", "Tenant by Datasource", "Most Run Reports"];

export const Troubleshooting = () => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [requestId, setRequestId] = useState(null);
	const [datasourceId, setDatasourceId] = useState(null);
	const [tab, setTab] = useState(tabItems[0]);
	const [queryExtras, setQueryExtras] = useState(null);
	const [queryExtrasError, setQueryExtrasError] = useState(null);

	const {
		data: requestDetails,
		isFetching: requestDetailsLoading,
		refetch: fetchRequestDetails,
	} = useQuery({
		queryKey: ["RequestDetails", selectedEnv, requestId],
		queryFn: async () => {
			const res = await callAPI({
				url: `report-support/requests/${requestId}`,
				selectedEnv: selectedEnv,
			});
			return res.data;
		},
		refetchOnWindowFocus: false,
		enabled: false,
	});

	const {
		data: tenantDetails,
		isFetching: tenantDetailsLoading,
		refetch: fetchTenantDetails,
		isError: tenantDetailsError,
	} = useQuery({
		queryKey: ["TenantDetails", selectedEnv, datasourceId],
		queryFn: async () => {
			const res = await callAPI({
				url: `datasource-info/${datasourceId}`,
				selectedEnv: selectedEnv,
			});
			return res.data;
		},
		refetchOnWindowFocus: false,
		enabled: false,
	});

	const {
		data: reportRunCounts,
		isLoading: reportRunCountsLoading,
		isError: reportRunCountsError,
		refetch: reportRunCountsRefetch,
	} = useQuery({
		queryKey: ["Report Runs"],
		queryFn: async () => {
			const res = await callAPI({
				url: `analytics/report-run-count`,
				params: queryExtras ? `&queryExtras=${encodeURIComponent(queryExtras)}` : "",
				selectedEnv: selectedEnv,
			});
			return res.data;
		},
		enabled: tab === "Most Run Reports",
	});

	if (selectedEnv === null) return <NoEnvSelected />;

	return (
		<div className="troubleshooting">
			<h1 style={{ textAlign: "center" }}>Report Troubleshooting</h1>
			<Tabs
				tabs={tabItems}
				defaultTab={tabItems[0]}
				onChange={(e) => {
					setTab(e);
				}}
				tabGroupName={"TroubleshootTabs"}
			/>
			{tab === "Request Details" && (
				<>
					<Textbox
						title={"Request ID:"}
						defaultValue={requestId}
						isRequired
						onChange={(e) => setRequestId(e.target.value)}
					/>
					<div style={{ width: "100%" }}>
						<Button
							unpadded
							takeContainerWidth
							icon={requestDetailsLoading && <FontAwesomeIcon icon={faSpinner} spin />}
							text={"Search for Request Details"}
							onClick={() => fetchRequestDetails()}
						/>
					</div>
					{requestDetails &&
						(requestDetails?.status?.length === 0 ? (
							<p>No request found</p>
						) : (
							<>
								<CopyText textToCopy={requestDetails.status}>Report Status: {requestDetails.status}</CopyText>
								<CopyText textToCopy={requestDetails.query}>Report Query: {requestDetails.query}</CopyText>
								<CopyText textToCopy={requestDetails.exception}>Report Exception: {requestDetails.exception}</CopyText>
								<pre>{`Report JSON: ${JSON.stringify(requestDetails.report, null, 2)}`}</pre>
							</>
						))}
				</>
			)}
			{tab === "Tenant by Datasource" && (
				<>
					<Textbox
						title={"Datasource ID:"}
						defaultValue={datasourceId}
						isRequired
						onChange={(e) => setDatasourceId(e.target.value)}
					/>
					<div style={{ width: "100%" }}>
						<Button
							unpadded
							takeContainerWidth
							icon={tenantDetailsLoading && <FontAwesomeIcon icon={faSpinner} spin />}
							text={"Search for Tenant"}
							onClick={() => fetchTenantDetails()}
						/>
					</div>
					{tenantDetailsError ? (
						<p>Error finding tenant</p>
					) : (
						tenantDetails && (
							<>
								<CopyText textToCopy={tenantDetails.tenantName}>Tenant Name: {tenantDetails.tenantName}</CopyText>
								<CopyText textToCopy={tenantDetails.tenantId}>Tenant ID: {tenantDetails.tenantId}</CopyText>
								<CopyText textToCopy={tenantDetails.dataSourceId}>Datasource ID: {tenantDetails.dataSourceId}</CopyText>
								<CopyText textToCopy={tenantDetails.dataSourceName}>
									Datasource Name: {tenantDetails.dataSourceName}
								</CopyText>
							</>
						)
					)}
				</>
			)}
			{tab === "Most Run Reports" && (
				<>
					<div style={{ display: "flex", height: "32px", marginTop: "10px", alignItems: "center" }}>
						<Textbox
							placeholder={"Enter your queryExtras SQL query."}
							defaultValue={null}
							value={queryExtras ?? ""}
							onChange={(e) => {
								setQueryExtrasError(null);

								if (e.target.value.toUpperCase().includes("WHERE")) {
									setQueryExtrasError('The API already includes "WHERE" clause.');
								}

								setQueryExtras(e.target.value);
							}}
							isError={queryExtrasError}
							errorMessage={queryExtrasError}
						/>
						<Button
							type={"CTA"}
							text={"Search"}
							onClick={() => {
								if (queryExtras?.indexOf("'") >= 0 && queryExtras?.indexOf('"') >= 0) {
									const unclosedQuotesRegex = /(["'])(?:(?=(\\?))\2.)*?\1/;
									if (!unclosedQuotesRegex.test(queryExtras)) {
										return setQueryExtrasError("Ensure all quotes are closed");
									}
								}
								if (queryExtrasError === null) {
									return reportRunCountsRefetch();
								}
							}}
						/>
						<Button
							text={"Clear"}
							onClick={async () => {
								await setQueryExtras(null);
								reportRunCountsRefetch();
							}}
						/>
					</div>
					{reportRunCountsLoading ? (
						<LoadingSpinner />
					) : (
						<>
							<table
								style={{
									textAlign: "left",
									marginLeft: "10vw",
									marginRight: "10vw",
								}}
							>
								<tr>
									<th style={{ fontWeight: "bold" }}>Saved Report Id</th>
									<th style={{ fontWeight: "bold" }}>Run Count</th>
								</tr>
								{!reportRunCountsError &&
									reportRunCounts.map((r) => (
										<tr key={r?.savedReportId}>
											<td>
												<CopyText textToCopy={r?.savedReportId}>{r?.savedReportId}</CopyText>
											</td>
											<td>{r?.runs}</td>
										</tr>
									))}
							</table>
						</>
					)}
				</>
			)}
		</div>
	);
};
