import { Button, Header, NestedList, Sidebar } from "@streetsheaver/sh-ui";
import "./App.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowRightFromBracket,
	faArrowsRotate,
	faBugSlash,
	faFile,
	faFileExport,
	faFileUser,
	faFill,
	faHome,
	faRightLeft,
	faUser,
	faUsers,
} from "@fortawesome/pro-light-svg-icons";
import RepGen from "./images/RepGen.png";
import { useContext, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useOutsideAlerter } from "./hooks/useOutsideAlerter";
import { EnvironmentContext } from "./context/EnvironmentContextProvider";
import { setTheme } from "./helpers/ThemeHelpers";

function App() {
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [userPanelOpen, setUserPanelOpen] = useState(false);
	const activeItem = new URL(window.location.href).pathname;
	const userPanelRef = useRef(null);
	const navigate = useNavigate();
	const { instance } = useMsal();
	const handleLogin = () => {
		instance.loginRedirect();
	};
	useOutsideAlerter(userPanelRef, () => setUserPanelOpen(false));
	const { selectedEnv } = useContext(EnvironmentContext);

	const menuItems = [
		{
			title: "Theme",
			icon: <FontAwesomeIcon icon={faFill} />,
			onClick: () => {
				document.documentElement.className === "lightRepGen" ? setTheme("dark") : setTheme("light");
			},
		},
		{
			title: "Log Out",
			icon: <FontAwesomeIcon icon={faArrowRightFromBracket} />,
			onClick: () => instance.logoutRedirect(),
		},
	];

	return (
		<div>
			<UnauthenticatedTemplate>
				<Header appName={"Report Generator Support Tool"} appLogo={RepGen} searchBoxEnabled={false} />
				<div className="LandingPage">
					<img src={RepGen} className="LandingPage__image" alt="appLogo" />
					<h3>Report Generator</h3>
					<Button type={"CTA"} text={"Log in"} onClick={handleLogin} />
				</div>
			</UnauthenticatedTemplate>
			<AuthenticatedTemplate>
				<div>
					<Header
						appName={"Report Generator Support Tool"}
						appLogo={RepGen}
						extraText={selectedEnv}
						searchBoxEnabled={false}
						headerIcons={[
							{
								icon: <FontAwesomeIcon icon={faUser} />,

								onClick: () => setUserPanelOpen(!userPanelOpen),
								primary: true,
							},
						]}
					/>
					{userPanelOpen && (
						<div className="userPanel" ref={userPanelRef}>
							<NestedList firstMenuJustIcons={false} data={menuItems} dropdown={true} side={"left"} />
						</div>
					)}
					<div className="main" style={{ height: "calc(100vh - 56px)" }}>
						<Sidebar
							sidebarOpen={sidebarOpen}
							setSidebarOpen={setSidebarOpen}
							sidebarData={[
								{
									title: "Home",
									onClick: () => navigate("/"),
									icon: <FontAwesomeIcon icon={faHome} />,
									isActive: activeItem === "/",
								},
								{
									title: "Manage Tenants",
									icon: <FontAwesomeIcon icon={faUsers} />,
									onClick: () => navigate("/tenants"),
									isActive:
										activeItem === "/tenants" ||
										activeItem.includes("/datasources") ||
										activeItem.includes("/email") ||
										(activeItem.includes("/conversion") && !activeItem.includes("/conversions")),
								},
								{
									title: "Manage System Reports",
									icon: <FontAwesomeIcon icon={faFile} />,
									onClick: () => navigate("/systemreports"),
									isActive: activeItem === "/systemreports",
								},
								{
									title: "Report Troubleshooting",
									icon: <FontAwesomeIcon icon={faBugSlash} />,
									onClick: () => navigate("/troubleshooting"),
									isActive: activeItem === "/troubleshooting",
								},
								{
									title: "Update Base Report Documents",
									icon: <FontAwesomeIcon icon={faArrowsRotate} />,
									onClick: () => navigate("/updatebase"),
									isActive: activeItem === "/updatebase",
								},
								{
									title: "Audits",
									icon: <FontAwesomeIcon icon={faFileUser} />,
									onClick: () => navigate("/audit"),
									isActive: activeItem === "/audit",
								},
								{
									title: "Conversions",
									icon: <FontAwesomeIcon icon={faRightLeft} />,
									onClick: () => navigate("/conversions"),
									isActive: activeItem.includes("/conversions"),
								},
								{
									title: "Move Reports To Datasource",
									icon: <FontAwesomeIcon icon={faFileExport} />,
									onClick: () => navigate("/movereports"),
									isActive: activeItem.includes("/movereports"),
								},
								{
									title: "Onboarding a Tenant",
									icon: <FontAwesomeIcon icon={faUser} />,
									onClick: () =>
										window.open(
											"https://support.streets-heaver.com/support/solutions/articles/103000185163-onboarding-a-tenant",
											"_blank",
										),
								},
							]}
						/>
						<Outlet />
					</div>
				</div>
			</AuthenticatedTemplate>
		</div>
	);
}

export default App;
