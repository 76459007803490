import { Tabs } from "@streetsheaver/sh-ui";
import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router";
import "./TenantSettings.scss";

const TabOptions = ["Email Settings", "Conversion Settings", "Delete Reports"];
const getDefaultTab = () => {
	switch (true) {
		case window.location.pathname.includes("email"):
			return TabOptions[0];
		case window.location.pathname.includes("conversion"):
			return TabOptions[1];
		case window.location.pathname.includes("delete"):
			return TabOptions[2];

		default:
			return TabOptions[0];
	}
};

const getRoute = (tab) => {
	switch (tab) {
		case TabOptions[0]:
			return "email";
		case TabOptions[1]:
			return "conversion";
		case TabOptions[2]:
			return "delete";

		default:
			return "conversion";
	}
};

export default function TenantSettings() {
	const navigate = useNavigate();
	const { tenantName } = useParams();
	const tenantId = useLocation()?.state?.id;
	return (
		<div className='TenantSettings'>
			<p className='TenantSettings__title'>{tenantName} - Settings</p>
			<Tabs
				tabGroupName={"Tenant Settings"}
				tabs={TabOptions}
				defaultTab={getDefaultTab()}
				onChange={(e) => {
					navigate(`/${tenantName}/settings/${getRoute(e)}`, { state: { id: tenantId } });
				}}
			/>
			<div className='TenantSettings__outlet'>
				<Outlet />
			</div>
		</div>
	);
}
