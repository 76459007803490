import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { callAPI } from "../../helpers/callAPI";
import { useContext } from "react";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { ListCard } from "@streetsheaver/sh-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/pro-light-svg-icons";

export const ConversionDetails = () => {
	const conversionId = useParams().conversionId;
	const { selectedEnv } = useContext(EnvironmentContext);

	const { data: details, isLoading: detailsLoading } = useQuery({
		queryKey: ["Conversion Details", conversionId],
		queryFn: async () => {
			const detailsRes = await callAPI({ selectedEnv: selectedEnv, url: `conversion-info-details/${conversionId}` });
			return detailsRes.data;
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;

	if (detailsLoading) return <LoadingSpinner />;

	return (
		<div className="conversion">
			<h1>Conversion History - {conversionId}</h1>
			{details.map((d) => {
				const isFailure = d.status === "Failure";
				if (isFailure) {
					const errorDetails = JSON.parse(d.conversionMessage);
					console.log(errorDetails);
					return (
						<ListCard
							title={`${errorDetails.title} failed to convert`}
							icon={<FontAwesomeIcon icon={faCircleXmark} />}
							subtext1={d.message.split(";")[0]}
						/>
					);
				}
				return (
					<ListCard
						key={d.id}
						title={`${d.title} converted succesfully`}
						icon={<FontAwesomeIcon icon={faCircleCheck} />}
						subtext1={`Legacy ID: ${d.legacyId}`}
						subtext2={`Converted ID: ${d.convertedId}`}
					/>
				);
			})}
		</div>
	);
};
