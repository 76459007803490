import { useInfiniteQuery } from "@tanstack/react-query";
import { callAPI } from "../../helpers/callAPI";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useContext } from "react";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import "./Conversion.scss";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { GhostCard, ListCard } from "@streetsheaver/sh-ui";
import dayjs from "dayjs";
import { useNavigate } from "react-router";

export const Conversion = () => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const navigate = useNavigate();

	const {
		data: conversions,
		isLoading: conversionsLoading,
		fetchNextPage: fetchMoreConversions,
		isFetchingNextPage,
	} = useInfiniteQuery({
		queryKey: ["Conversion History", selectedEnv],
		queryFn: async ({ pageParam }) => {
			const res = await callAPI({
				url: `conversion-batches`,
				selectedEnv: selectedEnv,
				params: `&amount=${pageParam ?? "20"}`,
			});
			const promises = res.data.map(async (conv) => {
				const statusRes = await callAPI({ url: `conversion-info/${conv.id}`, selectedEnv: selectedEnv });
				return {
					...conv,
					successes: statusRes.data?.find((s) => s.status === "Success")?.amount ?? null,
					failures: statusRes.data?.find((s) => s.status === "Failure")?.amount ?? null,
				};
			});

			const result = await Promise.all(promises);
			return result;
		},
		refetchOnWindowFocus: false,
		retry: (count, error) => {
			if (error?.response?.status === 404) return false;
			return true;
		},
		getNextPageParam: (_last, _all) => {
			if (_all.flatMap((a) => a).length === _last.length) return _all.length * 20 + 20;
			return undefined;
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;

	if (conversionsLoading) return <LoadingSpinner />;

	return (
		<div
			className="conversion"
			onScroll={(e) => {
				if (e.target.clientHeight * 1.005 >= e.target.scrollHeight - e.target.scrollTop) {
					fetchMoreConversions();
				}
			}}
		>
			<h1>Conversion History</h1>
			{conversions.pages.at(-1).map((conv) => (
				<ListCard
					key={conv.id}
					title={`${conv.tenantName} converted on ${dayjs(conv.dateTime).format("MMMM D, YYYY [at] HH:mm")}`}
					subtext1={conv.successes && `Successes: ${conv.successes}`}
					subtext2={conv.failures && `Failures: ${conv.failures}`}
					button1={{
						label: "View Conversion Details",
						type: "CTA",
						onClick: () => navigate(`${conv.id}`),
					}}
				/>
			))}
			{isFetchingNextPage && <GhostCard />}
		</div>
	);
};
