import React, { useState } from "react";
import "./ClarityBanner.scss";
import { Button } from "@streetsheaver/sh-ui";

export const ClarityBanner = () => {
	const [clarityRefused, setClarityRefused] = useState(window.localStorage.getItem("ClarityRefused"));

	if (clarityRefused !== null) return null;
	return (
		<div className="CookieBanner">
			<p className="CookieBanner_text">
				We improve our products by using Microsoft Clarity to see how you use our website. By using our site, you agree
				that we and Microsoft can collect and use this data. To opt-out of this, press Decline below
			</p>
			<div className="CookieBanner__buttons">
				<div>
					<Button
						text={"Accept"}
						type={"cta"}
						unpadded
						onClick={() => {
							setClarityRefused(false);
							localStorage.setItem("ClarityRefused", false);
						}}
					/>
				</div>
				<div>
					<Button
						text={"Decline"}
						unpadded
						onClick={() => {
							const delete_cookie = function (name) {
								document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
							};
							window.clarity("stop");
							localStorage.setItem("ClarityRefused", true);
							const cookiesToDelete = ["_clck", "_clsk"];
							cookiesToDelete.forEach((name) => delete_cookie(name));
							window.location.reload();
						}}
					/>
				</div>
			</div>
		</div>
	);
};
