import { faFile } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function LoadingSpinner() {
	return (
		<div>
			<FontAwesomeIcon style={{ position: "absolute", top: "50%", left: "50%", fontSize: "48px" }} icon={faFile} flip color='var(--appColour)' />
		</div>
	);
}
