import React, { createContext, useEffect, useState } from "react";
import { setTheme } from "../helpers/ThemeHelpers";

export const EnvironmentContext = createContext();

export const EnvironmentContextProvider = ({ children }) => {
	const [selectedEnv, setSelectedEnv] = useState(JSON?.parse(sessionStorage.getItem("RepGenSupportEnv")) ?? null);

	useEffect(() => {
		const NewThemeMap = {
			lightRepGen: "light",
			darkRepGen: "dark",
		};
		setTheme(NewThemeMap?.[localStorage.getItem("RepGenTheme")] ?? localStorage.getItem("RepGenTheme"));
	}, []);

	return (
		<EnvironmentContext.Provider
			value={{
				selectedEnv,
				setSelectedEnv,
			}}
		>
			{children}
		</EnvironmentContext.Provider>
	);
};
