import { Button } from "@streetsheaver/sh-ui";
import React from "react";
import { useNavigate } from "react-router";
import "./NoEnvSelected.scss";

export default function NoEnvSelected() {
	const navigate = useNavigate();
	return (
		<div className='noEnvSelected'>
			<h1>Please select an environment on the home page to continue</h1>
			<Button text={"Go to home"} onClick={() => navigate("/")} type={"CTA"} />
		</div>
	);
}
