import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
	auth: {
		clientId: "ad2996c5-4239-432e-aff1-456e0906a685",
		authority: "https://login.microsoftonline.com/73b2231a-113f-4276-954d-6aa6139488ca/",
		redirectUri: window.location.protocol + "//" + window.location.host,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						// eslint-disable-next-line no-console
						console.error(message);
						return;
					default:
						return;
				}
			},
		},
	},
};
