import { faClipboard } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CopyText = ({ textToCopy, textToShow, children }) => {
	return (
		<div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
			<p>{children}</p>
			{textToCopy?.length > 0 && (
				<FontAwesomeIcon
					style={{ cursor: "pointer" }}
					icon={faClipboard}
					onClick={() => navigator.clipboard.writeText(textToCopy)}
				/>
			)}
		</div>
	);
};
