import React, { useContext } from "react";
import "./Home.scss";
import { Card } from "@streetsheaver/sh-ui";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";

export default function Home() {
	const { selectedEnv, setSelectedEnv } = useContext(EnvironmentContext);
	const chooseEnvironment = (env) => {
		setSelectedEnv(env);
		window.sessionStorage.setItem("RepGenSupportEnv", JSON.stringify(env));
	};
	return (
		<div className='home'>
			<h1>Dev or Production?</h1>
			<div className='home__cards'>
				<Card
					title={"Dev"}
					actionRequired={true}
					customContent={() => <></>}
					button={{
						type: selectedEnv === "Dev" ? "secondary" : "CTA",
						text: selectedEnv === "Dev" ? "Selected" : "Select",
						onClick: () => chooseEnvironment("Dev"),
					}}
				/>
				<Card
					title={"Production"}
					actionRequired={true}
					customContent={() => <></>}
					button={{
						type: selectedEnv === "Production" ? "secondary" : "CTA",
						text: selectedEnv === "Production" ? "Selected" : "Select",
						onClick: () => chooseEnvironment("Production"),
					}}
				/>
			</div>
		</div>
	);
}
