import { useQuery, useQueryClient } from "@tanstack/react-query";
import { callAPI } from "../helpers/callAPI";

export const useTenantDatasources = (selectedEnv, tenantId) => {
	const queryClient = useQueryClient();
	const datasourcesInCache = queryClient.getQueryData(["Datasources", selectedEnv, tenantId]);
	const { data: datasources, isLoading: datasourcesLoading } = useQuery({
		queryKey: ["Datasources", selectedEnv, tenantId],
		queryFn: async () => {
			if (!tenantId) return null;
			if (datasourcesInCache) return datasourcesInCache;
			const res = await callAPI({ url: `tenants/${tenantId}/data-sources`, selectedEnv: selectedEnv });
			return res.data;
		},
		refetchOnWindowFocus: false,
	});
	return { datasources, datasourcesLoading };
};
