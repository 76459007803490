export function openOrDownloadFile(blob, name) {
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(blob, name);
	} else {
		if (!window.chrome) {
			const data = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = data;
			link.download = "" + name;
			link.click();
		} else {
			const url = window.URL.createObjectURL(blob);
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", name);
			document.body.appendChild(link);
			link.click();
			link.remove();
		}
	}
}
