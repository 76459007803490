import { Button, Header } from "@streetsheaver/sh-ui";
import React from "react";
import RepGen from "../../images/RepGen.png";
import { useNavigate } from "react-router-dom";
import "./ErrorPage.scss";

export default function ErrorPage() {
	const navigate = useNavigate();
	return (
		<div>
			<Header appName={"Report Generator"} appLogo={RepGen} searchBoxEnabled={false} mobileAlert={false}></Header>
			<div className="Error">
				<img src={RepGen} className="Error__image" alt="logo" />
				<h1 className="Error__uhoh">Uh oh!</h1>
				<h2 className="Error__something">Something went wrong</h2>
				<div className="Error__text">
					<p> Report Generator encountered a hiccup and crashed unexpectedly.</p>
					<p>
						What you can do: {<br />} 1. Retry: Give it another go; sometimes, glitches just need a moment to vanish.{" "}
						{<br />} 2. Contact Support: If the problem persists or you need assistance, reach our friendly support team
						{<br />}
						Here are some useful links:
					</p>
					<p>
						We apologize for any inconvenience caused and appreciate your patience. Stay tuned for updates as we work to
						get RepGen up and running smoothly again.
					</p>
				</div>
				<Button type={"CTA"} text={"Take me to the homepage"} onClick={() => navigate("/")} />
			</div>
		</div>
	);
}
