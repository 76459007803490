import React, { useContext, useState } from "react";
import "./Datasources.scss";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { Button, ListCard, Popup, Selectbox, Textbox } from "@streetsheaver/sh-ui";
import { faCheck, faCircleExclamation, faDotCircle, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useLocation, useParams } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTenantDatasources } from "../../hooks/useTenantDatasources";
import { callAPI } from "../../helpers/callAPI";

const datasourceStatuses = ["Online", "Offline", "UnderMaintenance", "Hidden"];
const DatasourceBadgeColours = {
	Online: "green",
	Offline: "red",
	UnderMaintenance: "orange",
	ConnectionError: "grey",
};

const RenderCard = ({ datasource, setTestDatasourcePopup, setAddingDatasource, setIsDeleting }) => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const tenantId = useLocation()?.state?.id;

	const testDatasource = useMutation({
		mutationKey: ["Test Datasource", datasource.id],
		mutationFn: async (datasource) => {
			const res = await callAPI({
				url: `tenants/${tenantId}/data-sources/${datasource.id}/test`,
				selectedEnv: selectedEnv,
			});
			setTestDatasourcePopup({ visible: true, result: { ...datasource, ...res.data } });
			return res.data;
		},
	});
	return (
		<ListCard
			key={datasource.id}
			title={
				<div className="datasources__cards__title">
					<p className="datasources__cards__title__text">{`${datasource.name}`}</p>
					<FontAwesomeIcon
						className="datasources__cards__title__status"
						icon={faDotCircle}
						color={DatasourceBadgeColours[datasource.status]}
						title={datasource.status}
					/>
					<FontAwesomeIcon
						className="datasources__cards__title__icon"
						icon={faTrash}
						onClick={() =>
							setIsDeleting({
								visible: true,
								datasourceName: datasource.name,
								userEnteredName: "",
								datasourceId: datasource.id,
							})
						}
					/>
				</div>
			}
			subtext1={`Datasource ID: ${datasource.id}`}
			subtext2={`Connection String: ${datasource.connectionString}`}
			button1={{
				label: "Edit Datasource",
				onClick: () =>
					setAddingDatasource({
						visible: true,
						editing: true,
						datasourceInfo: {
							name: datasource.name,
							tenantId: datasource.tenantId,
							connectionString: datasource.connectionString,
							tenantName: datasource.tenantName,
							status: datasource.status,
							datasourceId: datasource.id,
						},
					}),
			}}
			button2={{
				label: "Test Datasource",
				onClick: () => testDatasource.mutate(datasource),
			}}
		/>
	);
};

export default function Datasources() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [addingDatasource, setAddingDatasource] = useState({
		visible: false,
		editing: false,
		datasourceInfo: { name: "", tenantId: "", connectionString: "", tenantName: "", status: "", datasourceId: "" },
	});
	const [isDeleting, setIsDeleting] = useState({
		visible: false,
		datasourceName: "",
		userEnteredName: "",
		datasourceId: "",
	});
	const [testDatasourcePopup, setTestDatasourcePopup] = useState({ visible: false, result: {} });
	const tenantName = useParams()?.tenantName;
	const tenantId = useLocation()?.state?.id;
	const queryClient = useQueryClient();
	const { datasources, datasourcesLoading } = useTenantDatasources(selectedEnv, tenantId);

	const addDatasource = useMutation({
		mutationFn: async () => {
			const obj = {
				Name: addingDatasource?.datasourceInfo?.name,
				TenantId: tenantId,
				ConnectionString: addingDatasource?.datasourceInfo?.connectionString,
			};
			return await callAPI({
				body: obj,
				selectedEnv: selectedEnv,
				url: `tenants/${tenantId}/data-sources`,
				method: "post",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["Datasources", tenantId]);
			setAddingDatasource({
				visible: false,
				editing: false,
				datasourceInfo: { name: "", tenantId: "", connectionString: "", tenantName: "", status: "", datasourceId: "" },
			});
		},
	});

	const updateDatasource = useMutation({
		mutationFn: async () => {
			const originalDatasource = datasources.find((d) => d.id === addingDatasource?.datasourceInfo?.datasourceId);
			const updatedProperties = {};
			Object.keys(addingDatasource?.datasourceInfo).forEach((key) => {
				if (key === "datasourceId") return;
				if (originalDatasource[key] !== addingDatasource?.datasourceInfo[key]) {
					updatedProperties[key] = addingDatasource?.datasourceInfo[key];
				}
			});

			return await callAPI({
				body: updatedProperties,
				selectedEnv: selectedEnv,
				url: `tenants/${tenantId}/data-sources/${addingDatasource?.datasourceInfo?.datasourceId}`,
				method: "patch",
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["Datasources"]);
			setAddingDatasource({
				visible: false,
				editing: false,
				datasourceInfo: { name: "", tenantId: "", connectionString: "", tenantName: "", status: "", datasourceId: "" },
			});
		},
	});

	const deleteDatasource = useMutation({
		mutationFn: async () =>
			await callAPI({
				method: "delete",
				selectedEnv: selectedEnv,
				url: `tenants/${tenantId}/data-sources/${isDeleting?.datasourceId}`,
			}),
		onSuccess: () => {
			queryClient.invalidateQueries(["Datasources"]);
			setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "" });
		},
	});

	console.log(testDatasourcePopup);

	if (selectedEnv === null) return <NoEnvSelected />;
	if (datasourcesLoading) return <LoadingSpinner />;

	return (
		<div className="datasources">
			<div className="datasources__title">
				<div className="datasources__title__button">
					<Button
						unpadded
						takeContainerWidth
						text={"Add Datasource"}
						type={"CTA"}
						onClick={() =>
							setAddingDatasource({
								visible: true,
								editing: false,
								datasourceInfo: {
									name: "",
									tenantId: "",
									connectionString: "",
									tenantName: "",
									status: "",
									datasourceId: "",
								},
							})
						}
					/>
				</div>
				<h1 className="datasources__title__text">Manage Datasources - {tenantName}</h1>
				<div></div>
			</div>
			<div className="datasources__cards">
				{datasources?.map((datasource) => (
					<RenderCard
						key={datasource.id}
						datasource={datasource}
						setAddingDatasource={setAddingDatasource}
						setTestDatasourcePopup={setTestDatasourcePopup}
						setIsDeleting={setIsDeleting}
					/>
				))}
			</div>
			<Popup
				visible={addingDatasource?.visible}
				onCloseButtonClick={() =>
					setAddingDatasource({
						visible: false,
						editing: false,
						datasourceInfo: {
							name: "",
							tenantId: "",
							connectionString: "",
							tenantName: "",
							status: datasourceStatuses[4],
							datasourceId: "",
						},
					})
				}
				onOutsideClick={() =>
					setAddingDatasource({
						visible: false,
						editing: false,
						datasourceInfo: {
							name: "",
							tenantId: "",
							connectionString: "",
							tenantName: "",
							status: datasourceStatuses[4],
							datasourceId: "",
						},
					})
				}
				noHeader
				noPadding
				renderContent={() => (
					<div className="addDatasource">
						<h4 className="addDatasource__title">
							{addingDatasource.editing
								? `Editing ${tenantName} - ${addingDatasource.datasourceInfo.name}`
								: "Add Datasource"}
						</h4>
						<Textbox
							placeholder={"Name"}
							title={"Datasource Name"}
							isError={addingDatasource.datasourceInfo.name === null}
							errorMessage={"Datasource name is required"}
							value={addingDatasource.datasourceInfo.name}
							onChange={(e) =>
								setAddingDatasource((prev) => ({
									...prev,
									datasourceInfo: { ...prev.datasourceInfo, name: e.target.value },
								}))
							}
						/>
						<Textbox
							placeholder={"Connection String"}
							title={"Connection String"}
							isError={addingDatasource.datasourceInfo.connectionString === null}
							errorMessage={"Connection string is required"}
							value={addingDatasource.datasourceInfo.connectionString}
							onChange={(e) =>
								setAddingDatasource((prev) => ({
									...prev,
									datasourceInfo: { ...prev.datasourceInfo, connectionString: e.target.value },
								}))
							}
						/>
						{addingDatasource?.editing && (
							<div style={{ width: "100%" }}>
								<Selectbox
									items={datasourceStatuses}
									key={addingDatasource.datasourceInfo.tenantId}
									title={`Datasource Status`}
									isError={addingDatasource.datasourceInfo.status === null}
									errorMessage={"Status is required"}
									placeholder={"Status"}
									onChange={(e) =>
										setAddingDatasource((prev) => ({ ...prev, datasourceInfo: { ...prev.datasourceInfo, status: e } }))
									}
									startingSelection={addingDatasource.datasourceInfo.status}
								/>
							</div>
						)}
						<div className="addDatasource__button">
							<Button
								text={addingDatasource.editing ? "Save Changes" : "Add Datasource"}
								type={"CTA"}
								takeContainerWidth
								unpadded
								onClick={() => {
									if (addingDatasource?.datasourceInfo?.name?.length === 0) {
										setAddingDatasource((prev) => ({
											...prev,
											datasourceInfo: { ...prev.datasourceInfo, name: null },
										}));
										return;
									}
									if (addingDatasource?.datasourceInfo?.connectionString?.length === 0) {
										setAddingDatasource((prev) => ({
											...prev,
											datasourceInfo: { ...prev.datasourceInfo, connectionString: null },
										}));
										return;
									}
									if (addingDatasource.editing && addingDatasource?.datasourceInfo?.connectionString?.length === 0) {
										setAddingDatasource((prev) => ({
											...prev,
											datasourceInfo: { ...prev.datasourceInfo, status: null },
										}));
										return;
									}
									addingDatasource.editing ? updateDatasource.mutate() : addDatasource.mutate();
								}}
							/>
						</div>
					</div>
				)}
			/>
			<Popup
				visible={isDeleting?.visible}
				onCloseButtonClick={() =>
					setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "", datasourceId: "" })
				}
				onOutsideClick={() =>
					setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "", datasourceId: "" })
				}
				noHeader
				noPadding
				renderContent={() => (
					<div className="addDatasource">
						<h4 className="addDatasource__title">{`Deleting ${tenantName} - ${isDeleting.datasourceName}`}</h4>
						<p>Are you sure you want to delete this datasource?</p>
						<p>This is a destructive action and cannot be undone</p>
						<p>Please type the name of the datasource you are deleting below and press confirm</p>
						<Textbox
							placeholder={"Name"}
							title={"Datasource Name"}
							value={isDeleting.userEnteredName}
							onChange={(e) => setIsDeleting((prev) => ({ ...prev, userEnteredName: e.target.value }))}
						/>

						{isDeleting.datasourceName === isDeleting.userEnteredName && (
							<div className="addDatasource__button">
								<Button
									text={"Delete Datasource"}
									type={"CTA"}
									takeContainerWidth
									unpadded
									onClick={() => deleteDatasource.mutate()}
								/>
							</div>
						)}
					</div>
				)}
			/>
			<Popup
				visible={testDatasourcePopup?.visible}
				onCloseButtonClick={() => setTestDatasourcePopup((prev) => ({ ...prev, visible: false }))}
				onOutsideClick={() => setTestDatasourcePopup((prev) => ({ ...prev, visible: false }))}
				noHeader
				noPadding
				renderContent={() => {
					const ukWestResult = testDatasourcePopup?.result?.ukWest;
					const ukSouthResult = testDatasourcePopup?.result?.ukSouth;
					if (ukWestResult || ukSouthResult) {
						return (
							<div className="testingPopup">
								<p className="testingPopup__title">Connection Test - {testDatasourcePopup?.result?.name}</p>
								{[ukWestResult, ukSouthResult].map((r, i) => (
									<>
										<div className="testingPopup__result">
											<p className="testingPopup__result__text">
												Connection Result {i === 0 ? "UK West" : "UK South"} - {r?.status}
											</p>
											{r?.status === "Success" || r?.status === "Elevated" ? (
												<FontAwesomeIcon icon={faCheck} className="testingPopup__tick" />
											) : (
												<FontAwesomeIcon icon={faCircleExclamation} className="testingPopup__cross" />
											)}
										</div>
										{r?.status === "Success" && <p>{r?.resultMessage}</p>}
										{r?.status === "Elevated" && (
											<>
												<p>{r?.resultMessage}</p>
												<p>
													Warning: Elevated permissions may mean this connection allows destructive operations in SQL
													Direct queries
												</p>
											</>
										)}
										{r?.status === "NotFound" && <p>{r?.resultMessage}</p>}
										{r?.status === "Errored" && <p>{r?.resultMessage || r?.title}</p>}
									</>
								))}
							</div>
						);
					}
					return (
						<div className="testingPopup">
							<p className="testingPopup__title">Connection Test - {testDatasourcePopup?.result?.name}</p>
							<div className="testingPopup__result">
								<p className="testingPopup__result__text">Connection Result - {testDatasourcePopup?.result?.status}</p>
								{testDatasourcePopup?.result?.status === "Success" ||
								testDatasourcePopup?.result?.status === "Elevated" ? (
									<FontAwesomeIcon icon={faCheck} className="testingPopup__tick" />
								) : (
									<FontAwesomeIcon icon={faCircleExclamation} className="testingPopup__cross" />
								)}
							</div>
							{testDatasourcePopup?.result?.status === "Success" && <p>{testDatasourcePopup?.result?.resultMessage}</p>}
							{testDatasourcePopup?.result?.status === "Elevated" && (
								<>
									<p>{testDatasourcePopup?.result?.resultMessage}</p>
									<p>
										Warning: Elevated permissions may mean this connection allows destructive operations in SQL Direct
										queries
									</p>
								</>
							)}
							{testDatasourcePopup?.result?.status === "NotFound" && (
								<p>{testDatasourcePopup?.result?.resultMessage}</p>
							)}
							{testDatasourcePopup?.result?.status === "Errored" && (
								<p>{testDatasourcePopup?.result?.resultMessage || testDatasourcePopup?.result?.title}</p>
							)}
						</div>
					);
				}}
			/>
		</div>
	);
}
