import React, { useContext, useState } from "react";
import "./Tenants.scss";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { Button, ListCard, Popup, Textbox } from "@streetsheaver/sh-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTrash } from "@fortawesome/pro-light-svg-icons";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useNavigate } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useTenants } from "../../hooks/useTenants";
import { callAPI } from "../../helpers/callAPI";
import { CopyText } from "../../components/CopyText/CopyText";

export default function Tenants() {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [addingTenant, setAddingTenant] = useState({
		visible: false,
		tenantInfo: { name: "", tenantId: "", details: { displayName: "", domain: "" } },
	});
	const [isDeleting, setIsDeleting] = useState({ visible: false, tenantName: "", userEnteredName: "", tenantId: "" });
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { tenantsLoading, tenants } = useTenants();

	const { data: activeUsers, isLoading: activeUsersLoading } = useQuery({
		queryKey: ["Active Users", selectedEnv],
		queryFn: async () => {
			const monthlyUsers = await callAPI({ url: `analytics/monthly-users`, selectedEnv: selectedEnv });
			return monthlyUsers.data;
		},
	});

	const addTenant = useMutation({
		mutationFn: async () => {
			const obj = {
				Name: addingTenant?.tenantInfo?.name,
				Id: addingTenant?.tenantInfo?.tenantId,
				Details: {
					DisplayName: addingTenant?.tenantInfo?.details?.displayName,
					Domain: addingTenant?.tenantInfo?.details?.domain,
				},
			};
			return await callAPI({
				method: "post",
				url: "tenants",
				body: obj,
				selectedEnv: selectedEnv,
			});
		},
		onSuccess: () => {
			setAddingTenant((prev) => ({ ...prev, visible: false }));
			queryClient.invalidateQueries(["Tenants"]);
		},
	});

	const deleteTenant = useMutation({
		mutationFn: (tenantId) => callAPI({ method: "delete", url: `tenants/${tenantId}`, selectedEnv: selectedEnv }),
		onSuccess: () => {
			setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "", tenantId: "" });
			queryClient.invalidateQueries(["Tenants"]);
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;
	if (tenantsLoading || activeUsersLoading) return <LoadingSpinner />;
	return (
		<div className="tenants">
			<div className="tenants__title">
				<div className="tenants__title__button">
					<Button
						unpadded
						takeContainerWidth
						text={"Add Tenant"}
						type={"CTA"}
						onClick={() =>
							setAddingTenant({
								visible: true,
								tenantInfo: { name: "", tenantId: "", details: { displayName: "", domain: "" } },
							})
						}
					/>
				</div>
				<div className="tenants__title__text">
					<h1 style={{ marginBottom: "4px" }}>Manage Tenants</h1>
					<p style={{ textAlign: "center" }}>Total Monthly Active Users: {activeUsers?.length}</p>
				</div>
				<div></div>
			</div>
			<div className="tenants__cards">
				{tenants?.map((tenant) => {
					return (
						<ListCard
							key={tenant.id}
							title={
								<CopyText textToCopy={tenant.name}>
									<div className="tenants__cards__card__title">
										<p style={{ fontSize: "18px", fontWeight: 600 }}>{tenant.name}</p>
										<FontAwesomeIcon
											style={{ color: tenant?.emailSettings ? "green" : "var(--system-critical)" }}
											icon={faEnvelope}
											title={tenant?.emailSettings ? "Email settings configured" : "No email settings configured"}
										/>
										<FontAwesomeIcon
											title="Delete"
											style={{ color: "var(--system-critical)", cursor: "pointer" }}
											icon={faTrash}
											onClick={() =>
												setIsDeleting({
													visible: true,
													tenantName: tenant.name,
													userEnteredName: "",
													tenantId: tenant.id,
												})
											}
										/>
									</div>
								</CopyText>
							}
							subtext1={
								<CopyText textToCopy={tenant.id}>
									<span style={{ fontSize: "12px" }}>{`Tenant ID: ${tenant.id}`}</span>
								</CopyText>
							}
							subtext2={`Monthly Active Users: ${activeUsers?.reduce(
								(acc, obj) => (obj?.tenantId === tenant.id ? acc + 1 : acc),
								0,
							)} `}
							subtext3={
								tenant.details && `DisplayName: ${tenant.details.displayName}, Domain: ${tenant.details.domain}`
							}
							button1={{
								label: "Manage Datasources",
								onClick: () => navigate(`/${tenant.name}/datasources`, { state: { id: tenant.id } }),
							}}
							button2={{
								label: "Manage Tenant Settings",
								type: "CTA",
								onClick: () => navigate(`/${tenant.name}/settings/email`, { state: { id: tenant.id } }),
							}}
						/>
					);
				})}
			</div>
			<Popup
				visible={addingTenant?.visible}
				onCloseButtonClick={() => setAddingTenant((prev) => ({ ...prev, visible: false }))}
				onOutsideClick={() => setAddingTenant((prev) => ({ ...prev, visible: false }))}
				noHeader
				noPadding
				renderContent={() => (
					<div className="addTenant">
						<h4 className="addTenant__title">Add Tenant</h4>
						<Textbox
							placeholder={"Name"}
							title={"Tenant Name"}
							isError={addingTenant?.tenantInfo?.name === null}
							errorMessage={"Tenant name is required"}
							value={addingTenant.tenantInfo.name}
							onChange={(e) =>
								setAddingTenant((prev) => ({ ...prev, tenantInfo: { ...prev.tenantInfo, name: e.target.value } }))
							}
						/>
						<Textbox
							placeholder={"Tenant ID"}
							title={"Tenant ID"}
							isError={addingTenant?.tenantInfo?.tenantId === null}
							errorMessage={"Tenant ID is required"}
							value={addingTenant.tenantInfo.tenantId}
							onChange={(e) =>
								setAddingTenant((prev) => ({ ...prev, tenantInfo: { ...prev.tenantInfo, tenantId: e.target.value } }))
							}
						/>
						<Textbox
							placeholder={"Display Name"}
							title={"Display Name"}
							isError={addingTenant?.tenantInfo?.details.displayName === null}
							errorMessage={"Display Name is required"}
							value={addingTenant.tenantInfo.details.displayName}
							onChange={(e) =>
								setAddingTenant((prev) => ({
									...prev,
									tenantInfo: {
										...prev.tenantInfo,
										details: { ...prev.tenantInfo.details, displayName: e.target.value },
									},
								}))
							}
						/>
						<Textbox
							placeholder={"Domain"}
							title={"Domain (Do not include the @)"}
							isError={addingTenant?.tenantInfo?.details.domain === null}
							errorMessage={"Domain is required"}
							value={addingTenant.tenantInfo.details.domain}
							onChange={(e) =>
								setAddingTenant((prev) => ({
									...prev,
									tenantInfo: { ...prev.tenantInfo, details: { ...prev.tenantInfo.details, domain: e.target.value } },
								}))
							}
						/>
						<div className="addTenant__button">
							<Button
								text={"Add Tenant"}
								type={"CTA"}
								takeContainerWidth
								unpadded
								onClick={() => {
									if (addingTenant?.tenantInfo?.name?.length === 0) {
										setAddingTenant((prev) => ({ ...prev, tenantInfo: { ...prev.tenantInfo, name: null } }));
										return;
									}
									if (addingTenant?.tenantInfo?.tenantId?.length === 0) {
										setAddingTenant((prev) => ({ ...prev, tenantInfo: { ...prev.tenantInfo, tenantId: null } }));
										return;
									}
									if (addingTenant?.tenantInfo?.details?.displayName?.length === 0) {
										setAddingTenant((prev) => ({
											...prev,
											tenantInfo: { ...prev.tenantInfo, details: { ...prev.tenantInfo.details, displayName: null } },
										}));
										return;
									}
									if (addingTenant?.tenantInfo?.details?.domain?.length === 0) {
										setAddingTenant((prev) => ({
											...prev,
											tenantInfo: { ...prev.tenantInfo, details: { ...prev.tenantInfo.details, domain: null } },
										}));
										return;
									}
									addTenant.mutate();
								}}
							/>
						</div>
					</div>
				)}
			/>
			<Popup
				visible={isDeleting?.visible}
				onCloseButtonClick={() =>
					setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "", tenantId: "" })
				}
				onOutsideClick={() => setIsDeleting({ visible: false, datasourceName: "", userEnteredName: "", tenantId: "" })}
				noHeader
				noPadding
				renderContent={() => (
					<div className="addDatasource">
						<h4 className="addDatasource__title">{`Deleting ${isDeleting?.tenantName}`}</h4>
						<p>Are you sure you want to delete this tenant?</p>
						<p>This is a destructive action and cannot be undone</p>
						<p>Please type the name of the tenant you are deleting below and press confirm</p>
						<Textbox
							placeholder={"Name"}
							title={"Tenant Name"}
							value={isDeleting.userEnteredName}
							onChange={(e) => setIsDeleting((prev) => ({ ...prev, userEnteredName: e.target.value }))}
						/>

						{isDeleting.tenantName === isDeleting.userEnteredName && (
							<div className="addDatasource__button">
								<Button
									text={"Delete Tenant"}
									type={"CTA"}
									takeContainerWidth
									unpadded
									onClick={() => deleteTenant?.mutate(isDeleting?.tenantId)}
								/>
							</div>
						)}
					</div>
				)}
			/>
		</div>
	);
}
