import { useMutation } from "@tanstack/react-query";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { useContext, useState } from "react";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { Button, Popup } from "@streetsheaver/sh-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark, faSpinner } from "@fortawesome/pro-light-svg-icons";
import { callAPI } from "../../helpers/callAPI";

export const UpdateBase = () => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const [popupIsVisible, setPopupIsVisible] = useState(false);

	const updateCosmosFilesMutation = useMutation({
		mutationFn: async () => {
			return await callAPI({ method: "post", url: `update-cosmos-support-files`, selectedEnv: selectedEnv });
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;

	return (
		<>
			<div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
				<div>
					<Button takeContainerWidth text={"Update Support Files"} onClick={() => setPopupIsVisible(true)} />
				</div>
			</div>
			<Popup
				onCloseButtonClick={() => setPopupIsVisible(false)}
				onOutsideClick={() => setPopupIsVisible(false)}
				visible={popupIsVisible}
				renderContent={
					<>
						<p>
							Are you sure? This will alter the internal documents used throughout Report Generator to build up reports.
							This should only be done by a developer with a valid reason
						</p>
						<div style={{ display: "flex", justifyContent: "center" }}>
							<div>
								<Button text={"Cancel"} onClick={() => setPopupIsVisible(false)} />
							</div>
							<div>
								<Button
									text={"Continue"}
									icon={updateCosmosFilesMutation.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
									type={"CTA"}
									onClick={() => updateCosmosFilesMutation.mutate()}
								/>
							</div>
						</div>
						{updateCosmosFilesMutation.isSuccess && (
							<div
								style={{ color: "green", display: "flex", alignItems: "center", gap: "4px", justifyContent: "center" }}
							>
								<FontAwesomeIcon icon={faCircleCheck} />
								<p>Cosmos Files Updated</p>
							</div>
						)}
						{updateCosmosFilesMutation.isError && (
							<div
								style={{ color: "red", display: "flex", alignItems: "center", gap: "4px", justifyContent: "center" }}
							>
								<FontAwesomeIcon icon={faCircleXmark} />
								<p>Cosmos Files Failed To Update</p>
							</div>
						)}
					</>
				}
			/>
		</>
	);
};
