import { Button, ListCard, Popup, Textbox, Toast } from "@streetsheaver/sh-ui";
import React, { useContext, useState } from "react";
import "./SystemReports.scss";
import { APIGatewayCode, APIGateways } from "../../helpers/APIGateways";
import { EnvironmentContext } from "../../context/EnvironmentContextProvider";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import NoEnvSelected from "../../components/NoEnvSelected/NoEnvSelected";
import { faFile } from "@fortawesome/pro-light-svg-icons";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { callAPI } from "../../helpers/callAPI";

export const SystemReports = () => {
	const { selectedEnv } = useContext(EnvironmentContext);
	const API_URL = APIGateways[selectedEnv];
	const API_CODE = APIGatewayCode[selectedEnv];
	const [addingDev, setAddingDev] = useState({ visible: false, id: "", idIsEmpty: false });
	const queryClient = useQueryClient();
	const [prodToast, setProdToast] = useState({ visible: false, text: "", type: "success" });

	const { data: preBuilts, isLoading: preBuiltsLoading } = useQuery({
		queryKey: ["PreBuilts", selectedEnv],
		queryFn: async () => {
			const res = await callAPI({
				selectedEnv: selectedEnv,
				url: `tenants/73b2231a-113f-4276-954d-6aa6139488ca/data-sources/25e3a368-c7de-445c-88ac-b5bc7c0b03f6/builtin-reports`,
			});
			return res.data;
		},
		refetchOnWindowFocus: false,
	});

	const addToDev = useMutation({
		mutationFn: async (id) => {
			const res = await callAPI({
				url: `tenants/73b2231a-113f-4276-954d-6aa6139488ca/data-sources/25e3a368-c7de-445c-88ac-b5bc7c0b03f6/builtin-reports`,
				method: "post",
				selectedEnv: selectedEnv,
				params: `&savedReportId=${id}`,
			});
			return res;
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["PreBuilts", selectedEnv]);
			setAddingDev({ visible: false, id: "", idIsEmpty: false });
		},
	});

	const moveToProd = useMutation({
		mutationFn: async (id) => {
			const { data: details } = await callAPI({
				url: `tenants/73b2231a-113f-4276-954d-6aa6139488ca/data-sources/25e3a368-c7de-445c-88ac-b5bc7c0b03f6/builtin-report/${id}`,
				selectedEnv: selectedEnv,
			});
			await axios.post(
				"https://func-reportgenerator-support-prod-001.azurewebsites.net/api/tenants/73b2231a-113f-4276-954d-6aa6139488ca/data-sources/25e3a368-c7de-445c-88ac-b5bc7c0b03f6/builtin-reports?code=Ly1oPmEt5hx1ZcrN3z8jGfVhz1p8KKeD-lNqg4omS9ziAzFu03YqZA==",
				details,
			);
			return details;
		},
		onSuccess: (res) => {
			setProdToast({ visible: true, text: `Successfully added ${res?.title} to Prod`, type: "success" });
		},
	});

	const deleteSystemReport = useMutation({
		mutationFn: async (id) => {
			const { data: details } = await axios.delete(
				`${API_URL}tenants/73b2231a-113f-4276-954d-6aa6139488ca/data-sources/25e3a368-c7de-445c-88ac-b5bc7c0b03f6/builtin-report/${id}?code=${API_CODE}`,
			);
			return details;
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries(["PreBuilts", selectedEnv]);
			setProdToast({ visible: true, text: `Successfully deleted from ${selectedEnv}`, type: "success" });
		},
	});

	if (selectedEnv === null) return <NoEnvSelected />;
	if (preBuiltsLoading) return <LoadingSpinner />;

	return (
		<div className="systemReports">
			<div className="systemReports__header">
				<div className="systemReports__button">
					{selectedEnv === "Dev" && (
						<Button
							unpadded
							takeContainerWidth
							text={"Add System Reports"}
							type={"CTA"}
							onClick={() => setAddingDev({ visible: true, id: "", idIsEmpty: false })}
						/>
					)}
				</div>
				<h1 className="systemReports__text">Manage System Reports - {selectedEnv}</h1>
				<div></div>
			</div>
			<div className="systemReports__content">
				{preBuilts.map((p) => {
					return (
						<ListCard
							title={p?.title}
							subtext1={p?.category}
							subtext2={p?.description}
							icon={faFile}
							key={p?.id}
							button1={{
								label: "Delete",
								onClick: () => deleteSystemReport.mutate(p?.id),
							}}
							button2={
								selectedEnv === "Dev" && {
									label: "Move to Prod",
									onClick: () => moveToProd.mutate(p?.id),
								}
							}
						/>
					);
				})}
			</div>
			<Popup
				visible={addingDev?.visible}
				onCloseButtonClick={() => setAddingDev({ visible: false, id: "", idIsEmpty: false })}
				onOutsideClick={() => setAddingDev({ visible: false, id: "", idIsEmpty: false })}
				noHeader
				noPadding
				renderContent={() => (
					<div className="addSystemDev">
						<Textbox
							value={addingDev?.id}
							placeholder={"Saved Report Id"}
							title={"Saved Report Id"}
							onChange={(e) => {
								const guidRegex = /[^0-9a-fA-F-]/g;
								const input = e.target.value;
								const cleanedInput = input.replace(guidRegex, "");
								setAddingDev((prev) => ({ ...prev, id: cleanedInput }));
							}}
							isError={addingDev?.idIsEmpty}
							errorMessage={"ID must not be empty"}
						/>
						<Button
							type={"CTA"}
							text={"Add Report to System Reports - Dev"}
							unpadded
							onClick={() => {
								if (addingDev?.id?.length === 0) {
									setAddingDev((prev) => ({ ...prev, idIsEmpty: true }));
									return;
								}
								addToDev.mutate(addingDev?.id);
							}}
						/>
					</div>
				)}
			/>
			<Toast
				type={prodToast.type}
				visible={prodToast?.visible}
				renderContent={() => <p>{prodToast?.text}</p>}
				timer={3000}
				timeoutFunction={() => setProdToast({ visible: false, title: "" })}
			/>
		</div>
	);
};
